import React from "react";
import { useToggle } from "react-use";
import { ReactComponent as DownloadIcon } from "../svgs/download.svg";
import { formErrorHandler } from "../utils/formErrorHandler";
import { Form, Formik } from "formik";
import { DateTimePickerInput } from "./Input";
import Spinner from "./Spinner";
import * as Yup from "yup";
import { dateCompare } from "../regex/date";

const validation = Yup.object().shape({
  dateEnd: Yup.date()
    .nullable()
    .test("date-match", "Doit être postérieure à la date de début", function (
      value
    ) {
      return dateCompare(this.parent.dateStart, value);
    }),
});
function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`md:px-4 px-2 flex items-center ${className}`}>
      {children}
    </div>
  );
}

export function SimpleExportLine({ label, handleDownload }) {
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  return (
    <div className="flex justify-between lg:justify-end px-4 py-6 border-b last:border-0 border-gray-100">
      <InlineBLockContent className="mr-auto">{label}</InlineBLockContent>
      <InlineBLockContent className="h-16 w-20">
        {isSubmitting ? (
          <Spinner small={true} noPadding={true} />
        ) : (
          <button
            type="button"
            className="w-full flex items-center font-bold focus:outline-none"
            onClick={() => {
              toggleSubmitting();
              handleDownload().then(toggleSubmitting);
            }}
          >
            <DownloadIcon className="fill-current h-6 w-6 m-auto md:mr-4" />
          </button>
        )}
      </InlineBLockContent>
    </div>
  );
}

export function DateExportLine({ label, handleDownload, dateFilter = "date" }) {
  return (
    <Formik
      validationSchema={validation}
      initialValues={{
        dateStart: null,
        dateEnd: null,
      }}
      onSubmit={(values, actions) => {
        try {
          const dates = {};
          if (values.dateStart !== null) {
            const dateStart = values.dateStart;
            dateStart.setHours(0, 0, 0, 0);
            dates[`${dateFilter}[after]`] = dateStart;
          }
          if (values.dateEnd) {
            const dateEnd = values.dateEnd;
            dateEnd.setHours(23, 59, 59, 999);
            dates[`${dateFilter}[before]`] = dateEnd;
          }
          handleDownload(dates).then(() => actions.setSubmitting(false));
          //onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        return (
          <Form className="flex flex-wrap justify-between lg:justify-end px-4 py-6 border-b last:border-0 border-gray-100">
            <InlineBLockContent className="mr-auto w-full lg:w-auto">
              {label}
            </InlineBLockContent>
            <InlineBLockContent className="w-2/5 md:w-auto">
              <DateTimePickerInput
                label="Date de début"
                name="dateStart"
                className="flex flex-col"
                onChange={(value) => {
                  setFieldValue("dateStart", value);
                }}
              />
            </InlineBLockContent>
            <InlineBLockContent className="w-2/5 md:w-auto">
              <DateTimePickerInput
                label="Date de fin"
                name="dateEnd"
                className="flex flex-col"
                onChange={(value) => {
                  setFieldValue("dateEnd", value);
                }}
              />
            </InlineBLockContent>
            <InlineBLockContent className="h-16 w-1/5 md:w-20">
              {isSubmitting ? (
                <Spinner small={true} noPadding={true} />
              ) : (
                <button
                  type="submit"
                  className="w-full flex items-center font-bold focus:outline-none"
                >
                  <DownloadIcon className="fill-current h-6 w-6 m-auto md:mr-4" />
                </button>
              )}
            </InlineBLockContent>
          </Form>
        );
      }}
    </Formik>
  );
}
