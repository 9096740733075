import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { HeaderBar } from "../../components/HeaderBar";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Spinner from "../../components/Spinner";
import { useDeleteWork, useQueryWorksInfinite } from "../../api/works";
import { flatMap, map, size } from "lodash-es";
import Alert from "../../components/Alert";
import { useDesktop } from "../../hooks/useDesktop";
import InlineBLockContentSortable, {
  InlineBLockContent,
} from "../../components/InlineBlockContentSortable";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import ExpansionPanel from "../../components/ExpansionPanel";
import { useQuerySkillDomainsNames } from "../../api/skillDomains";
import ClassicFilters from "../../components/Filter/ClassicFilters";
import { PermissionChecker, useDomain } from "../../contexts/permissions";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/Modal";
import WorkImportForm from "../../forms/WorkImportForm";

function WorksList({ filters }) {
  const [orderFilter, setOrderFilter] = useState({ "order[label]": "asc" });
  const { data, fetchMore, canFetchMore } = useQueryWorksInfinite({
    ...orderFilter,
    ...filters,
  });
  const [deleteWork] = useDeleteWork();
  const isDesktop = useDesktop();
  const works = flatMap(data, (page) => page["hydra:member"]);
  if (size(works) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucune fiche de poste" />
      </div>
    );
  }
  const gridTemplateColumn = `25% auto 25% 9% 5%`;
  const nbWorks = size(works);

  return (
    <div className="relative">
        <div className="absolute -top-16 py-4 right-0">
        {nbWorks} Poste{nbWorks > 1 ? "s" : ""}
        </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="label"
          >
            Poste
          </InlineBLockContentSortable>
          <InlineBLockContent>Résumé fonction</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="skillDomains.label"
          >
            Domaine(s)
          </InlineBLockContentSortable>
          <InlineBLockContent>Nombre de collaborateurs</InlineBLockContent>
          <InlineBLockContent>Action</InlineBLockContent>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(works, (node) => {
          return isDesktop ? (
            <Link
              to={`/rh/fiche_poste/${node.id}`}
              key={node.id}
              className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>{node.label}</InlineBLockContent>
              <InlineBLockContent>{node.description}</InlineBLockContent>
              <InlineBLockContent>
                <div className={"flex flex-col"}>
                  {map(node.skillDomains, (skillDomain, key) => (
                    <div key={key} className="mr-2">
                      {skillDomain.label}
                    </div>
                  ))}
                </div>
              </InlineBLockContent>
              <InlineBLockContent>{node.collaborators ? size(node.collaborators) : "0"}</InlineBLockContent>
              <InlineBLockContent className="justify-center">
                <button
                  className="text-red-500 focus:outline-none focus:shadow-none"
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const res = window.confirm(
                      "La suppression sera définitive",
                    );
                    if (res) {
                      try {
                        await deleteWork(node.id);
                      } catch (e) {}
                    }
                  }}
                >
                  <TrashIcon className="w-6 h-6 text-red-500 fill-current" />
                </button>
              </InlineBLockContent>
            </Link>
          ) : (
            <WorkMobileCard work={node} />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

function WorkMobileCard({ work }) {
  return (
    <ExpansionPanel
      key={work.id}
      title={work.label}
      // subtitle={get(workAccident, "agency.label")}
    >
      {/*<CreateWorkAccidentForm workAccident={workAccident} readOnly />*/}
      <Link
        className="btn mt-6 w-full block text-center"
        to={`/rh/fiche_poste/${work.id}`}
      >
        Modifier
      </Link>
    </ExpansionPanel>
  );
}
const getFiltersList = (skillDomains) => [
  {
    label: "Domaine de compétence",
    key: "skillDomains",
    options: map(skillDomains, (node) => ({
      label: node.label,
      value: node["@id"],
      id: node["@id"],
    })),
  },
];

export default function Works() {
  const { data: skillDomains } = useQuerySkillDomainsNames();
  const filtersList = getFiltersList(skillDomains);
  const [filters, setFilters] = useState({});
  return (
    <div>
      <Helmet>
        <title>Référentiel postes</title>
      </Helmet>
      <HeaderBar sticky={true} buttons={<CreateDropdown />}>Référentiel postes</HeaderBar>
      <div>
        <div className="px-8 mt-8 mb-8">
          <ClassicFilters
            filtersList={filtersList}
            onChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <WorksList filters={filters} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

function CreateDropdown() {
  const permissionsProcess = ["kdix.actions.skill_evaluation.edit_create"];
  const [modalImportOpen, setImportOpen] = React.useState(false);
  const [modalHelpOpen, setModalHelpOpen] = React.useState(false);
  const isDesktop = useDesktop();
  return (
    <PermissionChecker permissions={permissionsProcess}>
      <div className="cursor-pointer">
        <Dropdown
          label={
            <div className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none z-20">
              <PlusIcon className="w-4 h-4" />
            </div>
          }
          arrow={false}
          childrenClassName="bg-gray-700 text-white shadow-lg z-40 mx-2 text-base"
          childrenStyle={{ minWidth: "280px", right: "3rem", top: "6rem" }}
          relative={false}
        >
          <Link
            to={"/rh/fiche_poste/creer"}
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
          >
            <span>Créer une fiche de poste</span>
          </Link>
          {isDesktop && (
            <>
          <div
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
            onClick={() => {
              setImportOpen(true);
            }}
          >
            <span>Importer des fiches de poste (CSV)</span>
          </div>
          <div
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
            onClick={() => {
              setModalHelpOpen(true);
            }}
          >
            <span>Aide import CSV</span>
          </div>
          </>
          )}
        </Dropdown>
      </div>
      <ImportWorksModal
        setIsOpen={setImportOpen}
        modalIsOpen={modalImportOpen}
      />
     
    </PermissionChecker>
  );
}

function ImportWorksModal({ modalIsOpen, setIsOpen }) {
  const domain = useDomain();
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={"Importer des fiches de postes (CSV)"}
      >
        <WorkImportForm domain={domain} onSuccess={() => setIsOpen(false)} />
      </Modal>
    </>
  );
}

function HelpWorksImportModal({ modalIsOpen, setIsOpen }) {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={"Aide import CSV"}
      >
        <div>
          <p className="mb-2">
            <strong>Format du CSV pour l'import</strong>
          </p>
          <ul>
            <li>Le séparateur de champ est ; ou tabulation</li>
            <li>Les colonnes ne doivent pas avoir d'entête</li>
            <li>L'extension du fichier doit être .csv ou .txt</li>
          </ul>
          <p className="mt-2 mb-2">
            <strong>Liste des colonnes du CSV :</strong>
          </p>
          <ul>
            <li>Colonne 1 : Fiche de poste</li>
            <li>Colonne 2 : Domaine de compétences</li>
            <li>Colonne 3 : Compétence</li>
            <li>Colonne 4 : Description du niveau 0</li>
            <li>Colonne 5 : Description du niveau 1</li>
            <li>Colonne 6 : Description du niveau 2</li>
            <li>Colonne 7 : Description du niveau 3</li>
            <li>Colonne 8 : Description du niveau 4</li>
          </ul>
        </div>
      </Modal>
    </>
  );
}
